import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    const fetchLocationData = () => {
      // Use the ipinfo.io API to get location data based on the user's IP address
      fetch('https://ipinfo.io/json') // Replace YOUR_TOKEN_HERE with your actual ipinfo.io API token
        .then(response => response.json())
        .then(data => {
          // Process the location data
          const locationData = data.loc.split(',');
          const latitude = locationData[0];
          const longitude = locationData[1];
          const city = data.city;
          const region = data.region;
          const country = data.country;
  
          setCity(city);
          setRegion(region);
          setCountry(country);
          setFetchAttempted(true);
        })
        .catch(error => {
          console.error('Error fetching location:', error);
          // Handle error by setting default values or showing an error message
          setCountry('Unavailable');
          setRegion('');
          setCity('');
          setFetchAttempted(true);
        });
    };
  
    if (!fetchAttempted) {
      fetchLocationData();
    }
  }, [fetchAttempted]);

  const imageUrls = [
    'https://i.ibb.co/SdNny9V/cropped-20230930-204221275-i-OS-copia-min.jpg',
    'https://i.ibb.co/pb3RSLQ/cropped-20231018-234921161-i-OS-copia-min.jpg',
    'https://i.ibb.co/VvMVdJQ/cropped-20231019-022845424-i-OS-copia-min.jpg',
    'https://i.ibb.co/6NPmNHJ/cropped-20231019-023210495-i-OS-copia-min.jpg',
    'https://i.ibb.co/ByQ3xkp/cropped-20221216-034738408-i-OS-copia-min.jpg',
    'https://i.ibb.co/hYkpVxm/cropped-20230426-033602587-i-OS-copia-min.jpg',
    'https://i.ibb.co/pX6jpSL/cropped-20230919-043755798-i-OS-copia-min.jpg',
    'https://i.ibb.co/89H2vdq/cropped-20230923-002354234-i-OS-copia-min.jpg'
  ];

  return (
    <div className="container">
      <img 
        src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
        alt="Header Image"
        className="header-image"
      />
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'3'}
        coverflowEffect={{
        rotate: 15,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
       }}
      autoplay={{ 
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
      className="swiper-container"
    >
      {imageUrls.map(url => (
        <SwiperSlide key={url} className="swiper-slide">
          <img src={url} alt="slide_image"/>
        </SwiperSlide>
      ))}
        <div className="slider-controler">
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>

          <div className='textContainer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <p style={{ fontSize: '31px', margin: '0 0 10px 0' }}> 
            <strong>🎁90% DE DESCUENTO 🎁 Lesli❤︎</strong>
        </p>
        <div className="detailsText">
            <strong>📍 {city}, {country} {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
            />} ❤️21 años ♉️ Tauro<br/></strong>
            🟢 En línea ahora<br/><br/>

            Obtén acceso exclusivo a mi contenido e interactúa conmigo directamente. <br/><br/>
            🍑 💦 ¿Vives cerca del <strong> {city}</strong>?📍 Envíame un DM para crear contenido 😉 🍑 🔥
        </div>
    </div>



      <a href="https://ofmsites.pro/lesxli" id="customButton" >
      Enviame un mensaje aqui
        <img src="https://www.edigitalagency.com.au/wp-content/uploads/OnlyFans-logo-symbol-icon-png-blue-background.png" alt="Logo" />
      </a>

      <p className="exclusiveContent">
       <strong>¡Obtén acceso a mi cuenta privada ahora!<br/>
       ¡$3.60/pm por tiempo limitado!</strong>
      </p>
      <Analytics/>
    </div>
  );
}

export default App;
